import React, { useReducer, useEffect } from 'react';
import { navigate } from 'gatsby';
import Input from '../components/Input';
import Button from '../components/Button';
import Loader from '../components/Loader';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const isValidEmail = email => {
    if (email.match(re)) {
        return true;
    }
    return false;
};
// Get value from query URL
const getValueFromQuery = (str, value) => {
    if (str.match(value)) {
        return str.split(`${value}=`).pop().split('&')[0];
    }
    return null;
};

const apiAguamanUrl = 'https://api.aguamandelivery.com/api/v1';

const InvitationPage = props => {
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        email: null,
        name: null,
        host: {},
        error: null,
        loadingGet: true,
        loadingPost: false,
        resolved: false,
    });
    const token = getValueFromQuery(props.location.search, 't');

    useEffect(() => {
        if (token) {
            fetch(`${apiAguamanUrl}/client/invitation?token=${token}`)
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        setState({
                            ...state,
                            loadingGet: false,
                            host: result.data,
                        });
                    } else {
                    }
                })
                .catch(() => navigate('/'));
        } else {
            navigate('/');
        }
    }, []);

    const handleInput = (e, field) => setState({ ...state, error: null, [field]: e.target.value });

    const addEmailToList = e => {
        e.preventDefault();

        if (state.name && state.email && isValidEmail(state.email)) {
            const data = {
                token,
                nameInvited: state.name,
                emailInvited: state.email,
            };
            setState({
                ...state,
                error: null,
                loadingPost: true,
            });

            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: raw,
                redirect: 'follow',
            };

            fetch(`${apiAguamanUrl}/invitations`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        setState({
                            ...state,
                            loadingPost: false,
                            resolved: true,
                            error: null,
                        });
                        navigate('/invitacion-exitosa', { state: state.host });
                    } else {
                        setState({
                            ...state,
                            loadingPost: false,
                            resolved: false,
                            error: response.errors[0].msg,
                        });
                    }
                })
                .catch(error => {
                    setState({
                        ...state,
                        loadingPost: false,
                        resolved: false,
                        error: error.response.errors[0].msg,
                    });
                });
        } else {
            // show email error
            setState({
                ...state,
                error: 'Ingrese su nombre y correo electrónico.',
            });
        }
    };

    if (state.loadingGet) {
        return (
            <Layout>
                <SEO title="Invitación - Aguaman" />
                <section className="invitation-prop">
                    <div
                        className="invitation-prop-content"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Loader />
                    </div>
                </section>
            </Layout>
        );
    }
    if (state.resolved) {
        return (
            <Layout>
                <SEO title={`Aguaman - Invitación de {state.host.name.split(' ')[0]}`} />
                <section className="invitation-prop">
                    <div className="invitation-success-prop-content">
                        <div className="invitation-success-message">
                            <div className="success-envelope-image"></div>
                            <h1>¡Valdrá la pena!</h1>
                            <p className="invitation-text">
                                Descarga la app y pide tu <b> primera recarga</b> para que{' '}
                                {state.host.name.split(' ')[0]} se entere que aceptaste su
                                invitación.
                            </p>
                            <div className="invitation-success-buttons">
                                <div>
                                    <a
                                        className="button"
                                        href="https://play.google.com/store/apps/details?id=com.aguaman.app"
                                    >
                                        <div className="logo-google-play"></div>&nbsp; Google Play
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className="button"
                                        href="https://apps.apple.com/kz/app/aguaman/id1541778190"
                                    >
                                        <div className="logo-app-store"></div>&nbsp; App store
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
    return (
        <Layout>
            <SEO title="Aguaman - Invitación" pathname="/invitacion" />
            <section className="invitation-prop">
                <div className="invitation-prop-content">
                    <div className="invitation-main-message">
                        <div className="envelope-image"></div>
                        <h1>Las buenas invitaciones siempre se aceptan!</h1>
                        <p className="invitation-text">
                            {state.host.name.split(' ')[0]} quiere que <b>pruebes</b> el servicio de
                            botellones de agua a domicilio.
                        </p>
                        <p className="invitation-text">
                            Acepta su invitación con tu nombre y correo electrónico.
                        </p>
                    </div>
                    <div className="invitation-form-wrapper">
                        <form onSubmit={addEmailToList} className="invitation-form">
                            <h2>Ingresa tus datos</h2>
                            <Input
                                type="text"
                                placeholder="Escribe tu nombre aquí"
                                value={state.name || ''}
                                onChange={e => handleInput(e, 'name')}
                                disabled={state.loadingPost}
                            />
                            <Input
                                type="email"
                                placeholder="Correo electrónico"
                                value={state.email || ''}
                                onChange={e => handleInput(e, 'email')}
                                disabled={state.loadingPost}
                            />
                            <Button
                                color="button-success"
                                type="submit"
                                label="Aceptar invitación"
                                onClick={addEmailToList}
                                disabled={state.loadingPost}
                            />
                        </form>
                        <div>
                            <div style={{ height: 24 }}>{state.loadingPost && <Loader />}</div>
                            <p style={{ height: 24, textAlign: 'center' }}>{state.error}</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default InvitationPage;
